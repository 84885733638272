// import react from 'react'
import styled from 'styled-components'


export const Win95Button = styled.button`
    border-width: 2px;
    border-style: outset;
    border-color: buttonface;
    border-right-color: #424242;
    border-bottom-color: #424242;
    background: silver;
    color: black;
    padding: 6px;
    border-radius: 1px;
    
    &:hover {
        border: 2px inset #fff;
        background: silver;
        color: #424242;
        box-shadow: -1px -1px #000;
    }
    &:focus {
        border: 2px inset #fff !important;
        background: silver;
        color: #424242;
        box-shadow: -1px -1px #000 !important;
        outline: 0 !important;
    }
    &:active {
        border: 2px inset #fff !important;
        color: #424242;
        box-shadow: -1px -1px #000 !important;
        outline: 0 !important;
    }
    
    &.btn-primary{
        padding-left: 8px;
        padding-right: 8px;
    }
    &:focus{
        outline: 1px dotted;
    }
    &.disabled,&:disabled{
        cursor: default;
        background-color: silver;
        border-style: outset;
        border-color: buttonface;
        border-right-color: #424242;
        border-bottom-color: #424242;
        color: grey;
        text-shadow: 1px 1px #fff;
    }
`
    