import * as React from "react"
import { Link, graphql } from "gatsby"
import styled from 'styled-components'

import Seo from "../components/seo"
import background from '../images/background.png'

import { Win95Button } from "../components/Win96button"

const RainbowTitle = styled.h1`
  background: linear-gradient(to right, red, orange , yellow, green, cyan, blue, violet);
  color:transparent;
  text-align: center;
  -webkit-background-clip: text;
  background-clip: text;
  margin: 0px;
  padding: 16px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
`

const PageWrapper = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;

  &> img {
    position: absolute;
    width: 768px;
    height: auto;
    top: 50%;
    left: 50%;
    margin-left: -384px;
    margin-top: -384px;
    z-index: 1;
  }

  &> .options {
    z-index: 2;
    margin-top: 16px;
  }

  &> .title-container {
    z-index: 2;
    background-color: #F5f5f5;
  }

`

const Index = () => {

  return (
    <PageWrapper>
      <img src={background} alt="bgimage" />
      <div className="title-container">
        <RainbowTitle>MANYOLOSWAG.XYZ</RainbowTitle>
      </div>
      <div className="options">
        <Win95Button>
          <Link to="/blog">Blog</Link>
        </Win95Button>
      </div>
    </PageWrapper> 
  )
}

export default Index

/**
 * Head export to define metadata for the page
 *
 * See: https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-head/
 */
export const Head = () => <Seo title="Hola mundo" />

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          description
        }
      }
    }
  }
`
